import React from "react";
import "./contactUs.css"; // Styling for this section

const ContactFooter = () => {
  return (
    <section className="contact-footer">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p className="description">
          We'd love to hear from you! Reach out to us anytime.
        </p>
        <div className="contact-details">
          <p>Email: feedback.safespace@gmail.com</p>
          <p>Phone/Whatsapp: +234 8032532333</p>
          <p>Address: Currently office-less, but always online!</p>
        </div>
      </div>

      <div className="footer">
        {/* <div className="footer-content">
          <h3>SafeSpace</h3>
          <p>Enhancing collaboration and driving academic success.</p>
          <div className="social-icons">
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="#">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div> */}
        <div className="footer-bottom">
          <p>&copy; 2024 SafeSpace. All Rights Reserved.</p>
        </div>
      </div>
    </section>
  );
};

export default ContactFooter;
