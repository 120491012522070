import "./App.css";
import AboutUs from "./components/about/about";
import LandingPage from "./components/banner/landing-page";
import ContactFooter from "./components/contact/contactUs";
import FeaturesSection from "./components/features/features";
import PricingSection from "./components/pricing/pricing";
import TestimonialSection from "./components/testimonials/testimonials";

function App() {
  return (
    <div className="App">
      <LandingPage />
      <FeaturesSection />
      <AboutUs />
      <PricingSection />
      <TestimonialSection />
      <ContactFooter />
    </div>
  );
}

export default App;
