import React from "react";
import "./about.css";
import teamImage from "../../safespace.png"; // Replace with your actual image path
const AboutUs = () => {
  return (
    <section className="about-us-section">
      <div className="about-us-overlay"></div>
      <div className="about-us-content">
        <div className="about-us-text">
          <h2>About SafeSpace</h2>
          <p className="about-us-description">
            At SafeSpace, our mission is to empower students to excel in their
            academic pursuits by providing a focused, AI-driven platform that
            eliminates distractions. We believe in the power of collaboration
            and community, enabling students to connect, share, and grow
            together.
          </p>
          <p className="about-us-vision">
            Our vision is to create a world where students are equipped with the
            tools and support they need to succeed, both academically and
            personally. We are dedicated to constantly improving our platform to
            meet the ever-evolving needs of students worldwide.
          </p>
        </div>
        <div className="about-us-image">
          <img src={teamImage} alt="Our Team" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
