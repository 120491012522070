import React from "react";
import "./features.css"; // Add appropriate CSS here
import studentsConnect from "../../assets/photos/connect.jpg"; // Replace with your image path
import studentsOrganise from "../../assets/photos/organise.jpg"; // Replace with your image path
import studentsAi from "../../assets/photos/ai.jpg"; // Replace with your image path

const FeaturesSection = () => {
  return (
    <section className="academic-support-section">
      <h2>Achieve More with SafeSpace</h2>
      <p className="intro-text">
        Unite Collaboration, AI Assistance, and Organization in One App
      </p>

      <div className="content-wrapper">
        {/* First Block */}
        <div className="content-block">
          <img
            src={studentsConnect}
            alt="Student Writing on Board"
            className="support-image left-image"
          />
          <div className="text-content">
            <h3>Connect and Collaborate</h3>
            <p>
              SafeSpace lets you connect with other students to share helpful
              academic tips and knowledge. Everything is designed to eliminate
              distractions, support your studies and help you do your best.
            </p>
          </div>
        </div>

        {/* Second Block */}
        <div className="content-block reverse">
          <img
            src={studentsOrganise}
            alt="Student Studying"
            className="support-image right-image"
          />
          <div className="text-content">
            <h3>Stay Organised</h3>
            <p>
              SafeSpace provides a streamlined personal dashboard where you can
              easily organize your courses and create custom topics. Stay on top
              of deadlines and access all your academic resources in one place,
              so you can focus on achieving your goals without the clutter.
            </p>
          </div>
        </div>

        <div className="content-block">
          <img
            src={studentsAi}
            alt="Student Writing on Board"
            className="support-image left-image"
          />
          <div className="text-content">
            <h3>AI Assistance</h3>
            <p>
              SafeSpace’s AI Assistance offers smart, personalized support to
              elevate your studies. Create and organize custom flashcards
              tailored to your topics, receive instant guidance, and access
              study tips. Our AI tools help you stay focused and make learning
              more efficient.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FeaturesSection;
